<template>
  <div class="tab-pane" id="tabs-1" role="tabpanel" v-if="mergedDeals.length>0">
    <div class="lesiurpackages_box organized tours packages_boby" v-if="mergedDeals.code === 'domestic'">
      <div v-masonry transition-duration="1s" item-selector=".item" class="masonry-containerrow packages_boby_grid masonrybox" data-masonry='{"percentPosition": true }'>
        <lesiurtab-item v-masonry-tile class="item" v-for="(item, idx) in mergedDeals" :key="idx" :items="item" />
        </div>
    </div>
    <div class="lesiurpackages_box organized tours packages_boby" v-else>
      <div v-masonry transition-duration="1s" item-selector=".item" class="masonry-containerrow packages_boby_grid masonrybox" data-masonry='{"percentPosition": true }'>
        <lesiurtab-item v-masonry-tile class="item" v-for="(item, idx) in mergedDeals" :key="idx" :items="item" />
      </div>
    </div>
    <div class="lesiurpackages_box mt-0" v-if="mergedDeals.code === 'vacation_pack' && !isMore && state">
      <ContentLoading :type="device === 'mobile' ? 'mobile-deal':'deal'" />
    </div>
    <div ref="show-more-line"></div>
  </div>
</template>
<script>
import Vue from 'vue';
import { VueMasonryPlugin } from 'vue-masonry';
import TemplateLesiurTabContent from './TemplateLesiurtabContent';

Vue.use(VueMasonryPlugin);

export default {
  name: 'TabContentTheme1',
  mixins: [TemplateLesiurTabContent],
  components: {
    LesiurtabItem: () => import('@/components/home/lesiurtabItem/LesiurtabItemTheme1'),
    // DomesticTabItem: () => import('@/components/home/LesiurtabItemForDomestic'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  computed: {
    mergedDeals() {
      return this.rowGroup.reduce((accumulator, currentValue) => accumulator.concat(currentValue),
        []);
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.$redrawVueMasonry();
      }
    },
  },
  mounted() {
    this.$redrawVueMasonry();
  },
};
</script>
<style scoped>
.loadMore {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.loadMore > button {
  padding: 10px 24px;
  border-radius: 50px;
  border: 1px solid rgb(0, 97, 171);
  border: 1px solid rgba(var(--theme-primary));
  cursor: pointer;
}
.theme1 .lesiurpackages_box{
  margin-top: 0px;
}
</style>
